.DialogComponents.userConnectedDialogWrap{
  top: 88px;
  height: calc(100vh - 88px);
}
.userConnectedDialog.dialogWrap{
  position: absolute;
  top: 24px;
  right: 40px;
  background-color: transparent;
  .header.noTitle{
    display: none;
  }
  .dialogcontent{
    padding: 0;
  }
  .connectedDialogContent{
    .itemWrap{
      border-radius: 10px;
      background-color: rgba(255, 64, 0, 1);
      margin-bottom: 12px;
      padding: 18px;
    }
    .connectedWrap{
      h3{
        color: rgba(64, 36, 36, 1);
        font-family: "MPLUS1Code-Medium";
        font-size: 24px;
        font-weight: 500;
        font-style: Medium;
        letter-spacing: 0px;
        text-align: left;
        margin-bottom: 24px;
      }
    }
    .netWork{
      height: 12px;
      display: flex;
      justify-content: flex-end;
      &.wrong{
        justify-content: space-between;
      }
      .wrongTip{
        .icon{
          margin-right: 4px;
        }
      }
      p{
        color: rgba(64, 36, 36, 1);
        font-family: "MPLUS1Code-Medium";
        font-size: 14px;
        font-weight: 500;
        font-style: Regular;
        letter-spacing: 0px;
        text-align: right;
        line-height: 12px;
        display: flex;
        align-items: center;
        .icon{
          margin-right: 8px;
        }
      }
    }
    .address{
      height: 44px;
      border-radius: 5px;
      border: 1px solid rgba(64, 36, 36, 1);
      background-color: rgba(64, 36, 36, 0.25);
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 10px 9px;
      margin-top: 18px;
      p{
        color: rgba(64, 36, 36, 1);
        font-family: "MPLUS1Code-Regular";
        font-size: 24px;
        font-weight: 400;
        font-style: Regular;
        letter-spacing: 0px;
        text-align: right;
        line-height: 24px;
      }
    }
    .disconnectWrap{
      .disconnectBtn{
        display: block;
        border-radius: 5px;
        /* background-color: rgba(64, 36, 36, 1); */
        height: 44px;
        width: 100%;
        margin-top: 18px;
        font-family: "MPLUS1Code-Medium";
        font-size: 14px;
        line-height: 44px;
        font-weight: 500;
        font-style: Medium;
        letter-spacing: 0px;
        text-align: center;
        border: none;
        outline: none;
        cursor: pointer;
        color: rgb(64, 36, 36);
        border: 1px solid rgb(64, 36, 36);
        &:hover{
          /* background-color: rgba(64, 36, 36, .5); */
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
    .switchWrap{
      display: flex;
      justify-content: space-between;
      margin-top: 18px;
      p{
        flex: 1;
        color: rgba(64, 36, 36, 1);
        font-family: "MPLUS1Code-Regular";
        font-size: 12px;
        font-weight: 400;
        font-style: Regular;
        letter-spacing: 0px;
        text-align: left;
        line-height: 18px;
      }
      .switchBtn{
        display: block;
        height: 36px;
        width: 120px;
        border-radius: 5px;
        border: 1px solid rgba(64, 36, 36, 1);
        color: rgba(64, 36, 36, 1);
        background-color: transparent;
        font-family: "MPLUS1Code-Regular";
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        text-align: center;
        line-height: 14px;
        margin-left: 19px;
        outline: none;
        cursor: pointer;
        &:hover{
          border: 1px solid rgba(64, 36, 36, 1);
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
    .addWalletWrap{
      .tokensWrap{

      }
      .token{
        width: 57px;
        height: 36px;
        display: inline-block;
        border-radius: 5px;
        background-color: rgb(255, 64, 0);
        color: rgb(64, 36, 36);
        font-family: "MPLUS1Code-Medium";
        font-size: 12px;
        font-weight: 500;
        font-style: Medium;
        letter-spacing: 0px;
        text-align: center;
        line-height: 36px;
        border-radius: 5px;
        border: 1px solid rgba(64, 36, 36, 1);
        opacity: 1;
        &:hover{
          background-color: rgba(255, 255, 255, 0.2);
        }
        &.selected{
          background-color: rgba(64, 36, 36, 1);
          color: rgb(255, 64, 0);
          &:hover{
            background-color: rgba(64, 36, 36, .8);
          }
        }
      }
      .addBtn{
        display: block;
        width: 100%;
        color: rgb(64, 36, 36);
        border: 1px solid rgb(64, 36, 36);
        margin-top: 18px;
        &:hover{
          /* background-color: rgba(64, 36, 36, .5); */
          background-color: rgba(255, 255, 255, 0.2);
        }
        &.disabled{
          border-radius: 5px;
          border: 1px solid rgba(64, 36, 36, 0.4);
          color: rgba(64, 36, 36, 0.4);
          &:hover{
            background-color: transparent;
          }
        }
      }
    }
    .claimWrap{
      .top{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .available{
        .label{
            color: rgba(64, 36, 36, 1);
            font-family: "MPLUS1Code-Medium";
            font-size: 12px;
            font-weight: 500;
            font-style: Medium;
            letter-spacing: 0px;
            text-align: left;
        }
        .number{
          color: rgba(64, 36, 36, 1);
          font-family: "MPLUS1Code-Medium";
          font-size: 18px;
          font-weight: 500;
          font-style: Medium;
          letter-spacing: 0px;
          text-align: left;
          line-height: 18px;
          margin-top: 9px;
          span{
            color: rgba(64, 36, 36, 1);
            font-family: "MPLUS1Code-Regular";
            font-size: 12px;
            font-weight: 400;
            font-style: Regular;
            letter-spacing: 0px;
            text-align: left;
            line-height: 12px;
            margin-left: 9px;
          }
        }
      }
      .claimBtn{
        width: 89px;
        height: 36px;
        cursor: pointer;
        color: rgb(64, 36, 36);
        border: 1px solid rgb(64, 36, 36);
        margin-top: 0;
        line-height: 36px;
        &:hover{
          /* background-color: rgba(64, 36, 36, .5); */
          background-color: rgba(255, 255, 255, 0.2);
        }
        &.disabled {
          background-color: rgb(255, 64, 0);
          border: 1px solid rgba(64, 36, 36, 0.4);
          color: rgba(64, 36, 36, 0.4);
          cursor: not-allowed;
          &:hover{
            background-color: rgb(255, 64, 0);
          }
        }
      }
    }
  }
}
.addLoading{
  display: block;
  width: 150px;
  height: 100px;
  position: fixed;
  top: calc(50% - 25px);
  left: calc(50% - 50px);
  background-color: rgb(59, 59, 59);
  border-radius: 10px;
  text-align: center;
  align-items: center;
  z-index: 1000;
  &.open{
    display: block;
  }
  .loadicon{
    display: inline-block;
    animation-name: rotate;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    margin-top: 36px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1599px) {
  .userConnectedDialog.dialogWrap{
    top: 24px;
    right: 24px;
  }
}

@media screen and (max-width: 1199px) {
  .userConnectedDialog.dialogWrap{
    top: 24px;
    right: 20px;
  }
}

@media screen and (max-width: 880px) {
  .DialogComponents.userConnectedDialogWrap{
    top: 44px;
    height: calc(100vh - 44px);
  }
  .userConnectedDialog.dialogWrap{
    position: static;
    top: auto;
    right: auto;
  }
}