.inputWrap{
  border-radius: 5px;
  border: 1px solid rgba(64, 36, 36, 1);
  background-color: rgba(64, 36, 36, 0.25);
  padding: 10px 9px;
  display: flex;
  align-items: center;
  max-width: 320px;
  box-sizing: border-box;
  >input{
    height: 24px;
    flex: 1;
    max-width: 265px;
    background-color: transparent;
    border: none;
    outline: none;
    margin-left: 8px;
    text-align: right;

    color: rgba(64, 36, 36, 1);
    font-family: "MPLUS1Code-Medium";
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: right;
    line-height: 24px;
    &.empty{
      font-size: 12px;
    }
    &.amount{
      font-size: 24px;
    }
    -moz-appearance: textfield; /* Firefox */
    &::placeholder{
      color: rgba(64, 36, 36, 0.5);
      font-family: "MPLUS1Code-Regular";
      font-size: 12px !important;
      line-height: 24px;
      font-weight: 400;
      letter-spacing: 0px;
      text-align: right;
    }
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
  }
  &:hover, &.hover{
    border-radius: 5px;
    border: 1px solid rgba(64, 36, 36, 1);
    background-color: rgba(255, 255, 255, 0.2);
  }
   &.exErr{
    border-radius: 5px;
    border: 1px solid rgba(64, 36, 36, 1);
    background-color: rgba(255, 0, 0, 1);
    >input{
      text-decoration: line-through;
    }
   }
   &.sending{
    border-radius: 5px;
    border: 1px solid rgba(64, 36, 36, 0.4);
    background-color: rgba(64, 36, 36, 0.25);
   }
}

.txtInfo{
  margin-top: 0px;
  p{
    color: rgba(64, 36, 36, 1);
    font-family: "MPLUS1Code-Regular";
    font-size: 12px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 12px;
    margin-bottom: 9px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &:last-child{
      margin-bottom: 0;
    }
    .num{
      .copyWrap{
        margin-left: 8px;
        position: relative;
        cursor: pointer;
        #copyBtn{
          fill: red
        }
        .copied{
          display: inline-block;
          position: absolute;
          right: 12px;
          top: -24px;
          padding: 6px 8px;
          border-radius: 5px;
          background-color: rgba(34, 34, 34, 1);
          color: rgba(187, 187, 187, 1);
          font-family: "MPLUS1Code-Light";
          font-size: 12px;
          font-weight: 300;
          line-height: 12px;
        }
      }
    }
    &:nth-child(2){
      font-size: 14px;
      .num{
        font-size: 14px;
      }
    }
    &.tobeclaimed{
      .label,.num{
        color: rgba(64, 36, 36, 0.4);
      }
    }
    &.tobeclaimed, &.longevityPool{
      cursor: pointer;
      .label{
        i{
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 12px;
          border: 1px solid rgba(64, 36, 36, 0.4);
          font-style:normal;
          text-align: center;
          line-height: 12px;
        }
      }
    }
    &.longevityPool{
      .label{
        i{
          border: 1px solid rgba(64, 36, 36, 1);
        }
      }
    }
    .claimedTip{
      /* display: inline-block; */
      box-sizing: border-box;
      width: 100%;
      padding: 9px;
      margin-top: 9px;
      border-radius: 5px;
      background-color: rgba(64, 36, 36, 0.2);
      color: rgba(64, 36, 36, 1);
      font-family: "MPLUS1Code-Light";
      font-size: 12px;
      font-weight: 300;
      font-style: Light;
      letter-spacing: 0px;
      text-align: left;
      line-height: 14px;
      &.show{
        display: inline-block;
      }
      &.hide{
        display: none;
      }
    }
  }
}

.availableAmount{
  border-radius: 5px;
  border: 1px solid rgba(64, 36, 36, 1);
  background-color: rgba(64, 36, 36, 0.2);
  height: 44px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
  .label{
    color: rgba(64, 36, 36, 1);
    font-family: "MPLUS1Code-Medium";
    font-size: 12px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: left;
    line-height: 44px;
  }
  .num{
    color: rgba(64, 36, 36, 1);
    font-family: "DSEG14ClassicMini-Regular";
    font-size: 18px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: right;
    line-height: 44px;
  }
}
.claimBtn{
  width: 100%;
  height: 44px;
  border-radius: 5px;
  margin-top: 18px;
  background-color: rgba(64, 36, 36, 1);
  color: rgba(255, 64, 0, 1);
  font-family: "MPLUS1Code-Medium";
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  line-height: 44px;
  cursor: pointer;
  &:hover{
    background-color: #662a1d;
  }
  &:active{
    background-color: #9f3212;
  }
  &.disabled{
    color: #FF4000;
    cursor: not-allowed;
    background-color: rgba(64, 36, 36, 0.5);
  }
  .claiming{
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      margin-right: 9px;
      animation-name: rotate;
      animation-duration: 1.2s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
}

.link{
  margin-top: 18px;
  color: rgba(64, 36, 36, 1);
  font-family: "MPLUS1Code-Regular";
  font-size: 12px;
  font-weight: 400;
  font-style: Regular;
  letter-spacing: 0px;
  text-align: left;
  line-height: 12px;
  cursor: pointer;
}


@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

