.mobileTip{
  border-radius: 5px;
  padding: 9px;
  display: flex;
  justify-content: flex-start;
  i{
    color: rgba(255, 64, 0, 1);
    font-family: "MPLUS1Code-Regular";
    font-size: 12px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 18px;
    margin-right: 9px;
  }
  p{
    color: rgba(255, 64, 0, 1);
    font-family: "MPLUS1Code-Regular";
    font-size: 12px;
    font-weight: 400;
    font-style: Regular;
    letter-spacing: 0px;
    text-align: left;
    line-height: 18px;
  }
}