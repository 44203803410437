.connectButton{
  cursor: pointer;
  .connectBtn{
    display: flex;
    align-items: center;
    #head-wallet{
      width: 16px;
      height: 16px;
      fill: rgba(187, 187, 187, 1);
      /* fill: rgb(255, 64, 0); */
    }
    span.txt{
      display: inline-block;
      color: rgba(187, 187, 187, 1);
      /* color: rgb(255, 64, 0); */
      font-family: "MPLUS1Code-Regular";
      font-size: 12px;
      font-weight: 400;
      font-style: Regular;
      letter-spacing: 0px;
      text-align: right;
      line-height: 12px;
      margin-left: 10px;
    }
    &:hover{
      #head-wallet{
        fill: rgba(255, 255,255, 1);
      }
      span{
        color: rgba(255, 255,255, 1);
      }
    }
  }
  .correctConnected, .wrongChain{
    display: flex;
    align-items: center;
    .icon{
        margin-right: 10px;
        #head-wallet{
          width: 16px;
          height: 16px;
          fill: rgba(187, 187, 187, 1);
          /* fill: rgb(255, 64, 0); */
        }
        .h5{
          display: none;
        }
    }
    .h5{
      display: none;
    }
    .txt{
      color: rgba(187, 187, 187, 1);
      /* color: rgb(255, 64, 0); */
      font-family: "MPLUS1Code-Regular";
      font-size: 12px;
      font-weight: 400;
      font-style: Regular;
      letter-spacing: 0px;
      text-align: right;
      line-height: 12px;
      em{
        display: inline-block;
        padding: 0 10px;
        height: 18px;
        border-radius: 5px;
        background-color: rgba(187, 187, 187, 1);
        color: rgba(51, 51, 51, 1);
        font-family: "MPLUS1Code-Medium";
        font-size: 12px;
        font-weight: 500;
        font-style: Medium;
        letter-spacing: 0px;
        text-align: center;
        line-height: 18px;
        margin-left: 9px;
      }
    }
    .arrow{
      display: inline-block;
      color: rgba(187, 187, 187, 1);
      height: 13px;
      width: 8px;
      transform: rotate(90deg);
      margin-left: 9px;
    }
    &:hover{
      .icon{
        #head-wallet{
          fill: rgba(255, 255,255, 1);
          /* fill: rgb(255, 64, 0); */
        }
      }
      .txt{
        color: rgba(255, 255,255, 1);
        /* color: rgb(255, 64, 0); */
        em{
          background-color: rgba(255, 255,255, 1);
        }
      }
      .arrow{
        color: rgba(255, 255,255, 1);
      }
    }
    &.open{
      .icon{
        #head-wallet{
          fill: rgb(255, 64, 0);
        }
      }
      .txt{
        color: rgb(255, 64, 0);
        em{
          background-color: rgb(255, 64, 0);
          color: rgba(51, 51, 51, 1);
        }
      }
    }
  }
  .wrongChain{
    .icon{
      width: 15px;
      height: 14px;
      color: rgba(204, 0, 51, 1);
    }
    .txt{
      em{
        color: rgba(204, 0, 51, 1);
      }
    }
    .arrow{
      color: rgba(204, 0, 51, 1);
    }
    &:hover{
      .icon{
        color: rgba(255, 255,255, 1);
        #head-wallet{
          fill: rgba(255, 255,255, 1);
        }
      }
      .txt{
        color: rgba(255, 255,255, 1);
        em{
          color: rgba(255, 255,255, 1);
        }
      }
      .arrow{
        color: rgba(255, 255,255, 1);
      }
    }
  }
}

@media screen and (max-width: 880px) {
  .connectButton{
    .connectBtn{
      span.txt{
        display: none;
      }
    }
    .correctConnected, .wrongChain{
      span.icon{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 75px;
        margin-right: 0;
        i.h5{
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-left: 8px;
          #head-wallet{
            display: inline-block;
            width: 16px;
            height: 16px;
            fill: rgb(204, 0, 51);
            &:hover{
              fill: rgb(255, 64, 0);
            }
          }
        }
        /* #warn-icon{
          fill: rgba(187, 187, 187, 1);
          &:hover{
            fill: rgba(187, 187, 187, 1);
          }
        } */
      }
      span.txt{
        display: none;
      }
      span.txt.connected{
        display: inline-block;
        margin-right: 5px;
        color: rgba(187, 187, 187, 1);
        font-family: "MPLUS1Code-Regular";
        font-size: 12px;
        font-weight: 400;
        font-style: Regular;
        letter-spacing: 0px;
        text-align: right;
        line-height: 12px;
      }
      &:hover{
        span.icon{
          color: rgb(255, 64, 0);
          #warn-icon{
            fill: rgb(255, 64, 0);
            &:hover{
              fill: rgb(255, 64, 0);
            }
          }
          #head-wallet{
            fill: rgba(187, 187, 187, 1);
            &:hover{
              fill: rgba(187, 187, 187, 1);
            }
          }
          i.h5{
            #head-wallet{
              fill: rgb(255, 64, 0);
              &:hover{
                fill: rgb(255, 64, 0);
              }
            }
          }
        }
      }
      &.open{
        span.txt.connected{
          color: rgb(255, 64, 0);
        }
        span.icon{
          color: rgb(255, 64, 0);
          #warn-icon{
            fill: rgb(255, 64, 0);
            &:hover{
              fill: rgb(255, 64, 0);
            }
          }
          #head-wallet{
            fill: rgb(255, 64, 0);
            &:hover{
              fill: rgb(255, 64, 0);
            }
          }
          i.h5{
            #head-wallet{
              fill: rgb(255, 64, 0);
              &:hover{
                fill: rgb(255, 64, 0);
              }
            }
          }
        }
      }
    }
  }
}