.FullScreenDialogComponents{
  background-color: rgba(42, 34, 34, 0.8);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  backdrop-filter: blur(12px);
  .dialogWrap{
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    z-index: 101;
    background-color: rgba(51, 51, 51, 1);
    >.header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      width: 100%;
      height: 108px;
      box-sizing: border-box;
      padding: 32px 40px;
      &.noTitle{
        justify-content: flex-end;
      }
      .title{
        color: rgba(255, 64, 0, 1);
        font-family: "MPLUS1Code-Medium";
        font-size: 24px;
        font-weight: 500;
        font-style: Medium;
        letter-spacing: 0px;
        text-align: left;
        line-height: 30px;
      }
      .closeBtn{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 44px;
        border-radius: 5px;
        background-color: rgba(59, 59, 59, 1);
        cursor: pointer;
        img{
          width: 14px;
          height: 12px;
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1599px) {
  .FullScreenDialogComponents{
    .dialogWrap{
      >.header{
        height: 92px;
        padding: 24px;
      }
    }
  }
}

@media screen and (min-width: 981px) and (max-width: 1199px) {
  .FullScreenDialogComponents{
    .dialogWrap{
      >.header{
        height: 84px;
        padding: 20px;
      }
    }
  }
}

@media screen and (max-width: 980px) {
  .FullScreenDialogComponents{
    .dialogWrap{
      >.header{
        height: 44px;
        padding: 7px 18px;
        .closeBtn{
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}