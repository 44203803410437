.claimDialogComponent{
  .FullScreenDialogComponents{
    .dialogWrap{
      .dialogcontent{
        .contentWrap{
          display: flex;
          justify-content: space-between;
          padding: 0 40px;
          height: calc(100vh - 108px);
          box-sizing: border-box;
          width: 100%;
          .siderWrap{
            order: 2;
            width: 496px;
            margin-left: 32px;
            .poolInfoWrap{
              width: 100%;
              min-height: 107px;
              border-radius: 5px;
              background-color: rgba(42, 42, 42, 1);
              box-sizing: border-box;
              padding: 16px 16px 32px 16px;
              .txtInfo{
                margin-top: 0px;
                p{
                  color: rgba(187, 187, 187, 1);
                  font-family: "MPLUS1Code-Regular";
                  font-size: 14px;
                  font-weight: 400;
                  font-style: Regular;
                  letter-spacing: 0px;
                  text-align: left;
                  line-height: 14px;
                  margin-bottom: 12px;
                  display: flex;
                  justify-content: space-between;
                  flex-wrap: wrap;
                  &:last-child{
                    margin-bottom: 0;
                  }
                  .num{
                    .copyWrap{
                      margin-left: 8px;
                      position: relative;
                      cursor: pointer;
                      #copyBtn{
                        fill: red
                      }
                      .copied{
                        display: inline-block;
                        position: absolute;
                        right: 12px;
                        top: -24px;
                        padding: 6px 8px;
                        border-radius: 5px;
                        background-color: rgba(34, 34, 34, 1);
                        color: rgba(187, 187, 187, 1);
                        font-family: "MPLUS1Code-Light";
                        font-size: 12px;
                        font-weight: 300;
                        line-height: 12px;
                      }
                    }
                  }
                  &:nth-child(2){
                    font-size: 14px;
                    .num{
                      font-size: 14px;
                    }
                  }
                  &.tobeclaimed{
                    cursor: pointer;
                    .label,.num{
                      color: rgb(102, 102, 102);
                    }
                  }
                  &.longevityPool{
                    cursor: pointer;
                  }
                  .claimedTip{
                    /* display: inline-block; */
                    box-sizing: border-box;
                    width: 100%;
                    padding: 9px;
                    margin-top: 12px;
                    border-radius: 5px;
                    background-color: rgb(51, 51, 51);
                    color: rgb(102, 102, 102);
                    font-family: "MPLUS1Code-Light";
                    font-size: 14px;
                    font-weight: 300;
                    font-style: Light;
                    letter-spacing: 0px;
                    text-align: left;
                    line-height: 18px;
                    &.show{
                      display: inline-block;
                    }
                    &.hide{
                      display: none;
                    }
                  }
                }
              }
              .availableAmount{
                border: none;
                background-color: transparent;
                height: 24px;
                padding: 0;
                margin-top: 42px;
                .label{
                  color: rgba(255, 64, 0, 1);
                  font-family: "MPLUS1Code-Medium";
                  font-size: 14px;
                  font-weight: 500;
                  font-style: Medium;
                  letter-spacing: 0px;
                  text-align: left;
                  line-height: 14px;
                }
                .num{
                  color: rgba(255, 64, 0, 1);
                  font-family: "MPLUS1Code-Medium";
                  font-size: 24px;
                  font-weight: 500;
                  font-style: Medium;
                  letter-spacing: 0px;
                  text-align: right;
                  line-height: 24px;
                }
              }
            }
          }
          .primary.recordsWrap{
            flex: 1;
            order: 1;
            height: 100%;
            overflow-y: scroll;
            box-sizing: border-box;
            padding-right: 5px;
            &::-webkit-scrollbar {
              width: 3px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: rgba(29, 28, 28, 0.3);
              width: 3px;
              border-radius: 3px;
            }
            &::-webkit-scrollbar-track{
              background-color: transparent;
            }
            &::-webkit-scrollbar {
              background-color: transparent;
            }

            .item{
              margin-bottom: 24px;
              height: 24px;
              line-height: 24px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .left{
                .type{
                  color: rgba(102, 255, 0, 1);
                  font-family: "MPLUS1Code-Medium";
                  font-size: 12px;
                  font-weight: 500;
                  font-style: Medium;
                  letter-spacing: 0px;
                  text-align: left;
                  line-height: 24px;
                  display: inline-block;
                  width: 174px;
                  margin-right: 20px;
                }
                .date{
                  color: rgba(187, 187, 187, 1);
                  font-family: "MPLUS1Code-Light";
                  font-size: 12px;
                  font-weight: 300;
                  font-style: Light;
                  letter-spacing: 0px;
                  text-align: left;
                  line-height: 24px;
                }
              }
              .amount{
                color: rgba(102, 255, 0, 1);
                font-family: "MPLUS1Code-Medium";
                font-size: 24px;
                font-weight: 500;
                font-style: Medium;
                letter-spacing: 0px;
                text-align: right;
                line-height: 24px;
                span{
                  color: rgba(187, 187, 187, 1);
                  font-family: "MPLUS1Code-Light";
                  font-size: 12px;
                  font-weight: 300;
                  font-style: Light;
                  letter-spacing: 0px;
                  text-align: right;
                  line-height: 12px;
                }
              }
              &.claimed{
                .left{
                  .type{
                    color: rgb(204, 0, 51);
                  }
                }
                .amount{
                  color: rgb(204, 0, 51);
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1599px) {
  .claimDialogComponent{
    .FullScreenDialogComponents{
      .dialogWrap{
        .dialogcontent{
          .contentWrap{
            padding: 0 24px;
            height: calc(100vh - 92px);
            .siderWrap{
              width: 376px;
              margin-left: 24px;
              .poolInfoWrap{
                padding: 12px 12px 24px 12px;
                .availableAmount{
                  padding: 0;
                  margin-top: 34px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 981px) and (max-width: 1199px) {
  .claimDialogComponent{
    .FullScreenDialogComponents{
      .dialogWrap{
        .dialogcontent{
          .contentWrap{
            padding: 0 20px;
            height: calc(100vh - 84px);
            .siderWrap{
              width: 320px;
              margin-left: 24px;
              .poolInfoWrap{
                padding: 12px 12px 24px 12px;
                .availableAmount{
                  padding: 0;
                  margin-top: 34px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 980px) {
  .claimDialogComponent{
    .FullScreenDialogComponents{
      .dialogWrap{
        .dialogcontent{
          .contentWrap{
            padding: 0;
            height: calc(100vh - 44px);
            display: block;
            .siderWrap{
              width: 100%;
              margin-left: 0;
            }
            .primary.recordsWrap{
              padding: 18px 18px 0;
              height: calc(100vh - 44px - 135px);
              .item{
                margin-bottom: 18px;
                height: 40px;
                line-height: 24px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .left{
                  .type{
                    display: inline-block;
                    width: 100%;
                    line-height: 18px;
                  }
                  .date{
                    display: block;
                    width: 100%;
                    line-height: 12px;
                    margin-top: 8px;
                  }
                }
                .amount{
                  span{
                    display: inline-block;
                    width: 100%;
                    line-height: 12px;
                    margin-top: 4px;
                  }
                }
                &.claimed{
                  .left{
                    .type{
                      color: rgb(204, 0, 51);
                    }
                  }
                  .amount{
                    color: rgb(204, 0, 51);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}