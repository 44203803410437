.DialogComponents{
  background-color: rgba(42, 34, 34, 0.8);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  backdrop-filter: blur(12px);
  .dialogWrap{
    width: 357px;
    /* height: 279px; */
    border-radius: 10px;
    box-sizing: border-box;
    background-color: rgba(255, 64, 0, 1);
    padding: 0px 0 27px;
    position: relative;
    z-index: 101;
    &.success{
      background-color: #66FF00;
      height: 302px;
    }
    &.failed{
      background-color: #CC0033;
      height: 302px;
    }
    .header{
      display: flex;
      justify-content: space-between;
      position: relative;
      width: 100%;
      height: 44px;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 7px 18px 0;
      &.noTitle{
        justify-content: flex-end;
      }
      .title{
        color: rgba(64, 36, 36, 1);
        font-family: "MPLUS1Code-Medium";
        font-size: 24px;
        line-height: 30px;
        font-weight: 500;
        font-style: Medium;
        letter-spacing: 0px;
        text-align: left;
      }
      .closeBtn{
        position: absolute;
        display: block;
        width: 16px;
        height: 16px;
        top: 9px;
        right: 9px;
        cursor: pointer;
      }
    }
    .dialogcontent{
      padding: 18px 18px 0;
    }
  }
}