body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,fieldset,input,textarea,p,blockquote,th,td {
  margin:0;
  padding:0;
  }
  table {
  border-collapse:collapse;
  border-spacing:0;
  }
  fieldset,img {
  border:0;
  }
  address,caption,cite,code,dfn,em,strong,th,var {
  font-style:normal;
  font-weight:normal;
  }
  ol,ul {
  list-style:none;
  }
  caption,th {
  text-align:left;
  }
  h1,h2,h3,h4,h5,h6 {
  font-size:100%;
  font-weight:normal;
  }
  q:before,q:after {
  content:”;
  }
  abbr,acronym { 
  border:0;
  }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #333333;
  &.noScroll{
    height: 100vh;
    overflow: hidden;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button{
  display: inline-block;
  color: rgba(187, 187, 187, 1);
  font-family: "MPLUS1Code-Medium";
  font-size: 12px;
  line-height: 44px;
  font-weight: 500;
  font-style: Medium;
  letter-spacing: 0px;
  text-align: center;
  height: 44px;
  border-radius: 5px;
  background-color: rgba(59, 59, 59, 1);
  cursor: pointer;
  &.button_border{
    height: 42px;
    line-height: 42px;
    color: rgb(255, 64, 0);
    border: 1px solid rgba(255, 64, 0, 1);
    background-color: transparent;
    &:hover{
      background-color: rgba(255, 64, 0, 0.2);
    }
    &.disabled{
      border: 1px solid rgba(102, 102, 102, 1);
      color: rgba(102, 102, 102, 1);
      &:hover{
        border: 1px solid rgba(102, 102, 102, 1);
        color: rgba(102, 102, 102, 1);
        background-color: transparent;
        cursor: not-allowed;
      }
    }
  }
  &.button_primary{
    background-color: rgba(255, 64, 0, 1);
    color: rgba(51, 51, 51, 1);
    &:hover{
      background-color: #ff6632;
    }
  }
}

@font-face {
  font-family: "DSEG14ClassicMini-Regular";
  src: url("./assets/fonts/DSEG14ClassicMini-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "MPLUS1Code-Light";
  src: url("./assets/fonts/MPLUS1Code-Light.ttf") format("truetype");
}
@font-face {
  font-family: "MPLUS1Code-Regular";
  src: url("./assets/fonts/MPLUS1Code-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "MPLUS1Code-Medium";
  src: url("./assets/fonts/MPLUS1Code-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "MPLUS1Code-SemiBold";
  src: url("./assets/fonts/MPLUS1Code-SemiBold.ttf") format("truetype");
}