.headerWrap{
  width: 100%;
  box-sizing: border-box;
  height: 88px;
  background-color: rgba(34, 34, 34, 1);
  .inner{
    width: 100%;
    /* max-width: 1200px; */
    height: 88px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 13px 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &.pc{
      display: flex;
    }
    &.h5{
      display: none;
    }
    .leftWrap{
      display: flex;
      justify-content: flex-start;
      .logo{
        font-size: 0;
      }
      .nav{
        margin-left: 124px;
        display: flex;
        align-items: flex-end;
        a, span{
          display: inline-block;
          width: 240px;
          margin-right: 16px;
          text-decoration: none;
          opacity: 1;
          color: rgba(187, 187, 187, 1);
          /* color: rgba(187, 187, 187, 1); */
          /* color: color(display-p3 0, 0%, 73%); */
          font-family: "MPLUS1Code-Medium";
          font-size: 18px;
          font-weight: 500;
          font-style: Medium;
          letter-spacing: 0px;
          text-align: center;
          line-height: 18px;
          cursor: pointer;
          &:last-child{
            margin-right: 0;
          }
          
          &:hover{
            color: rgba(255, 255, 255, 1);
          }
          &:active{
            color: rgba(255, 64, 0, 1);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1599px) {
  .headerWrap{
    .inner{
      padding: 13px 24px;
      .leftWrap{
        .nav{
          margin-left: 86px;
          a{
            width: 182px;
            margin-right: 12px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .headerWrap{
    .inner{
      padding: 13px 20px;
      .leftWrap{
        .nav{
          margin-left: 44px;
          a{
            width: 154px;
            margin-right: 12px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 880px) {
  .headerWrap{
    height: 44px;
    .inner{
      height: 44px;
      padding: 0 18px;
      &.pc{display: none;}
      &.h5{display: flex;}
      .headerShow{
        display: flex;
        width: 100%;
        height: 44px;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        position: relative;
        .menu{
          background-image: url(../../assets/images/Icon-Menu-Mobile.svg);
          background-size: 18px 12px;
          background-repeat: no-repeat;
          width: 18px;
          height: 12px;
          display: inline-block;
          cursor: pointer;
        }
        .logo{
          width: 51px;
          height: 24px;
          position: absolute;
          left: 50%;
          transform: translateX(-25px);
        }
        .walletIcon{
          width: 17px;
          height: 16px;
          color: #bbbbbb;
          display: inline-block;
        }
      }
      .navWrap{
        width: 100vw;
        height: calc(100vh - 44px);
        background-color: rgba(34, 34, 34, 1);
        position: fixed;
        top: 44px;
        left: 0;
        z-index: 1000;
        .nav{
          span, a{
            display: block;
            width: 100%;
            height: 44px;
            color: rgba(187, 187, 187, 1);
            font-family: "MPLUS1Code-Medium";
            font-size: 18px;
            font-weight: 500;
            font-style: Medium;
            letter-spacing: 0px;
            text-align: center;
            line-height: 44px;
            text-decoration: none;
            margin-top: 18px;
          }
        }
        .mobileTipWrap{
          position: absolute;
          bottom: 52px;
          left: 27px;
          width: calc(100% - 54px);
          background-color: rgba(255, 64, 0, 0.2);
          border-radius: 5px;
          
        }
        .mediaWrap{
          position: fixed;
          bottom: 52px;
          width: 100%;
          display: flex;
          justify-content: center;
          .mediaItem{
            width: 44px;
            height: 44px;
            border-radius: 5px;
            border-radius: 5px;
            background-color: rgba(59, 59, 59, 1);
            color: rgb(255, 64, 0);
            margin-left: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:hover{
              background-color: rgba(59, 59, 59, .6);
            }
          }
        }
      }
    }
    .mobileTipWrap.warnDialogContent{
      border-radius: 5px;
      background-color: rgba(64, 36, 36, 1);
    }
  }
}