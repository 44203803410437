.resultTipContent{
  .successTip{
    padding-top: 62px;
    .top{
      display: flex;
      justify-content: center;
      align-items: center;
      p{
        color: rgba(64, 36, 36, 1);
        font-family: "MPLUS1Code-Medium";
        font-size: 24px;
        font-weight: 500;
        font-style: Medium;
        letter-spacing: 0px;
        text-align: right;
        line-height: 24px;
        margin: 0 0 0 9px;
        span{
          display: inline-block;
          color: rgba(64, 36, 36, 1);
          font-family: "MPLUS1Code-Medium";
          font-size: 14px;
          font-weight: 500;
          font-style: Medium;
          letter-spacing: 0px;
          text-align: left;
          line-height: 12px;
          margin: 0 0 0 9px;
        }
      }
    }
    .tips{
      color: rgba(64, 36, 36, 1);
      font-family: "MPLUS1Code-Medium";
      font-size: 12px;
      font-weight: 500;
      font-style: Medium;
      letter-spacing: 0px;
      text-align: center;
      line-height: 12px;
      margin-top: 18px;
    }
    
  }
  .failedTip{
    padding-top: 74px;
    .tips{
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgba(64, 36, 36, 1);
      font-family: "SFCompact-Medium";
      font-size: 30px;
      font-weight: 500;
      font-style: Medium;
      letter-spacing: 0px;
      text-align: center;
      line-height: 30px;
      img{
        margin-right: 12px;
      }
    }
  }

  .closeBtn{
    margin-top: 62px;
    border-radius: 5px;
    border: 1px solid rgba(51, 51, 51, 1);
    background-color: transparent;
    height: 44px;
    line-height: 44px;
    color: rgba(51, 51, 51, 1);
    font-family: "MPLUS1Code-Medium";
    font-size: 14px;
    font-weight: 500;
    font-style: Medium;
    letter-spacing: 0px;
    text-align: center;
    cursor: pointer;
    &:hover{
      background-color: rgba(255, 255, 255, .2);
    }
  }

  &.failed{
    .closeBtn{
      margin-top: 74px;
    }
  }
}